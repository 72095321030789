export function isPath(pathname, paths) {

  paths = !Array.isArray(paths) ? [paths] : paths;

  paths = paths.map(path => trailingslashit(path));
  pathname = trailingslashit(pathname);

  return paths.includes(pathname);
}

export function untrailingslashit(string) {
  return string.replace(/\/$/, "");
}

export function trailingslashit(string) {
  return untrailingslashit(string) + '/';
}

export function displayPrice(price, minDecimals = 0, maxDecimals = 2, spacer = '\u00A0', currency = '€') {

  if (price === false || isNaN(price)) {
    return '';
  }

  price = Number(price);

  if (typeof price !== 'number') {
    return '';
  }

  let countDecimals;

  if (Math.floor(price) === price) {
    countDecimals = 0;
  } else {
    countDecimals = price.toString().split(".")[1].length || 0;
  }

  if (countDecimals < minDecimals) {
    countDecimals = minDecimals;
  } else if (countDecimals > maxDecimals) {
    countDecimals = maxDecimals;
  }

  return (
    price
      .toFixed(countDecimals)
      .toString()
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + spacer + currency
  );

}

export function versionCompare(v1, v2, options) {

  var lexicographical = (options && options.lexicographical) || false,
    zeroExtend = (options && options.zeroExtend) || true,
    v1parts = (v1 || "0").split('.'),
    v2parts = (v2 || "0").split('.');

  function isValidPart(x) {
    return (lexicographical ? /^\d+[A-Za-zαß]*$/ : /^\d+[A-Za-zαß]?$/).test(x);
  }

  if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
    return NaN;
  }

  if (zeroExtend) {
    while (v1parts.length < v2parts.length) v1parts.push("0");
    while (v2parts.length < v1parts.length) v2parts.push("0");
  }

  if (!lexicographical) {
    v1parts = v1parts.map(function (x) {
      var match = (/[A-Za-zαß]/).exec(x);
      return Number(match ? x.replace(match[0], "." + x.charCodeAt(match.index)) : x);
    });
    v2parts = v2parts.map(function (x) {
      var match = (/[A-Za-zαß]/).exec(x);
      return Number(match ? x.replace(match[0], "." + x.charCodeAt(match.index)) : x);
    });
  }

  for (var i = 0; i < v1parts.length; ++i) {
    if (v2parts.length == i) {
      return 1;
    }

    if (v1parts[i] == v2parts[i]) {
      continue;
    } else if (v1parts[i] > v2parts[i]) {
      return 1;
    } else {
      return -1;
    }
  }

  if (v1parts.length != v2parts.length) {
    return -1;
  }

  return 0;
}

export function animate({timing = (time) => time, draw, duration}) {

  let start = performance.now();

  requestAnimationFrame(function animate(time) {

    let timeFraction = (time - start) / duration;
    if (timeFraction > 1) timeFraction = 1;

    let progress = timing(timeFraction);

    draw(progress);

    if (timeFraction < 1) {
      requestAnimationFrame(animate);
    }

  });

}

export function isDevelopment() {
  return process.env.NODE_ENV !== 'production';
}

export function getDate(d) {
  return `${d.getFullYear()}${padZero(d.getMonth() + 1)}${padZero(d.getDate())}`;
}

export function isSameDate(d1, d2) {
  return getDate(d1) === getDate(d2);
}

export function getFormattedDate(d) {
  const day = new Intl.DateTimeFormat('de-DE', {day: '2-digit'}).format(d);
  const month = new Intl.DateTimeFormat('de-DE', {month: '2-digit'}).format(d);
  const year = new Intl.DateTimeFormat('de-DE', {year: 'numeric'}).format(d);

  return `${day}.${month}.${year}`
}

export function getYesterdaysDate() {
  var date = new Date();
  date.setDate(date.getDate() - 1);
  return getFormattedDate(date);
}

export function getPathname(location) {
  return location.pathname.replace(/\/$/, "");
}

export function getCookie(name) {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function weekdayName(number) {
  switch (number) {
    case 0:
      return 'Sonntag'
    case 1:
      return 'Montag'
    case 2:
      return 'Dienstag'
    case 3:
      return 'Mittwoch'
    case 4:
      return 'Donnerstag'
    case 5:
      return 'Freitag'
    case 6:
      return 'Samstag'
  }
  return '';
}

export function removeArrayValue(value, array) {
  const index = array.indexOf(value);

  if (index > -1) {
    return [...array.slice(0, index), ...array.slice(index + 1)];
  }

  return array;
}

export function toggleArrayValue(value, array) {
  const index = array.indexOf(value);
  if (index > -1) {
    return removeArrayValue(value, array);
  } else {
    return [...array, value];
  }
}

export function getLastLocation() {
  let location = window.sessionStorage.getItem('echo-last-path');
  return location ? location : '/';
}

function padZero(num) {
  num = num.toString();
  while (num.length < 2) num = "0" + num;
  return num;
}

export function sanitizeTextForClassName(text, largeText = false) {
  let sanitizedText;

  if (largeText) {
    const words = text.toString().split(/\s+/);
    const firstWord = words[0];
    const lastWord = words[words.length - 1];

    // If the first and last words are the same, use only the first word for sanitization
    sanitizedText = firstWord === lastWord ? firstWord : `${firstWord} ${lastWord}`;
  } else {
    sanitizedText = text;
  }

  return sanitizedText.toString().toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^a-z0-9äöüß-]/gi, '')
      .replace(/-+$/, '');
}

export function abEchoClass(prefix = '', id = '') {

  let abEchoClass = `ab-echo`;
  let general = '';

  if (prefix !== '') {
    general += `${abEchoClass}-${prefix}`;
    abEchoClass += `-${prefix}`;
  }

  if (id !== '') {
    abEchoClass += `-${sanitizeTextForClassName(id)}`;
  } else {
    return general;
  }

  return `${general} ${abEchoClass}` ;
}